<template>
    <div class="title">
        <slot>
        </slot>
    </div>
</template>

<style scoped>
.title{
    font-size:70px;
    font-weight: 700;
    width: 85%;
}
</style>

<script>
export default {
    name: 'PageTitle',
}
</script>