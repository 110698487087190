<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  
  <div  :backcolor="bgColor" class="changebg"  ></div>
  <nav @click="returnHome()" v-show="this.$router.currentRoute._value.meta.depth !== 21">
    <img src="./assets/img/header_icon.png" alt="" > De Brillenstylist
  </nav>
  <router-view v-slot="{ Component }" @changeBackground="bgChange" :key="$route.fullPath">
    <transition name="slide-left" mode="out-in">
      <component @home="returnHome" :is="Component" class="comp" @selectStep="changeSelectedStep" :cameraStep="camstep"/>
    </transition>
  </router-view>
  <div v-if="showBottomNav" class="bottomNav">
    <div @click="this.$router.go(-1);this.$doneSteps[this.$router.currentRoute.value.meta.depth] = false"><img src="./assets/img/arrow_back_secondary.png" alt=""></div>
    <div @click="goTo()">ga verder ►</div>
    <div @click="goTo()"><img src="./assets/img/arrow_next_secondary.png" alt=""></div>
  </div>
</template>


<script>
// import 'aframe';
// import 'mind-ar/dist/mindar-face-aframe.prod.js';

export default {
  name: "App",
  data(){
    return{
      selectedStep: false,
      bgColor: "white",
      transitionName: "",
      camstep: 0
    }
  },
  computed:{
    showBottomNav(){
      if(
        this.$router.currentRoute.value.name !== 'home' &&
        this.$router.currentRoute.value.name !== 'not think page' &&
        this.$router.currentRoute.value.name !== 'what color start page' &&
        this.$router.currentRoute.value.name !== 'what shape start page' &&
        this.$router.currentRoute.value.name !== 'what gray start page' &&
        this.$router.currentRoute.value.name !== 'befor result page' &&
        this.$router.currentRoute.value.name !== 'results'
      ){
        return true;
      }
      return false;
    }
  },
  methods:{
    bgChange(c){
      this.bgColor = c
    },
    changeSelectedStep(e){
      if(e.type == "camera" && e.url == 1){
        this.camstep = e.url
      }
      this.selectedStep = e;
    },
    goTo(){
      this.$doneSteps[this.$router.currentRoute._value.meta.depth] = true;
      if(this.$router.currentRoute._value.meta.depth == 17){
        if(this.$doneSteps[2] == false){
          this.$router.push("/color-palet")
          return;
        }
      }
      if(this.$doneSteps[this.$router.currentRoute._value.meta.depth+1] == true){
          this.$router.push("/tips")
          return;

      }
      if(this.selectedStep.type == "camera"){
        this.camstep = this.selectedStep.url;
        return;
      }
      this.$router.push(this.selectedStep.url);
      this.camstep = 0;
      
    },
    returnHome(){
      this.$doneSteps = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false,
        15: false,
        16: false,
        17: false,
        18: false,
        19: false,
        20: false,
        21: false
      }
this.$userChoices = {
        palet: 0, //1 = cold - warm = 2
        color: 0,
        contrast: {
            skin: 0,
            hair: 0,
            eyes: 0
        },
        shape: {
            glasses: [],
            head: 0, //1 = round - 2 = square
            brow: 0, //1 = round/thin - 2 = straight/bold - 3 = round/bold - 4 = straight/thin
            chin: 0  //1 = straight/Scurve - 2 = straigth - 3 = round - 4 = round/curve
        },
        cloth: {
            text: {
                text1: "",
                text2: "",
                text3: ""
            },
            selectedOptions: []
        },
        userInfo: {
            fname: "",
            lname: "",
            birth: "",
            email: ""
        }
}
      this.$router.push('/');
      this.camstep=0;
    }
  },
  watch: {
    $route(to, from) {
    const toDepth = to.meta.depth || 0;
    const fromDepth = from.meta.depth || 0;

    this.transitionName = toDepth >= fromDepth ? 'slide-left' : 'slide-right';
    },
  },
}
</script>

<style>


#app {
  font-family: CenturyGothic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-color);
  position:fixed;
  left:0;
  top:0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
nav {
  width: 100%;
  height: 20px;
  padding: 20px 80px;
  position: absolute;
  left:0;
  top:0;
  align-items: center;
  display: flex;
  gap: 5px;
  color: var(--main-color);
  z-index: 10;
}
nav img{
  height: 100%;
  width: auto;
}

.comp{
  position: absolute;
  height: 90vh;
  width: 80vw;
  padding: 60px 0;
  left: 0; 
  right: 0; 
  margin: auto;  
  top: 0; 
  bottom: 0;
}
.changebg:before {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: -1;
  left: 0;
  top:0;
}

.changebg[backcolor="#f3776c"]:before{
  background: #f3776c;
}
.changebg[backcolor="#89d2d6"]:before{
  background: #89d2d6;
}
.changebg[backcolor="#9d957c"]:before{
  background: #9d957c;
}
.changebg[backcolor="#f3776c"]:before{
  background: #f3776c;
}

.bottomNav{
  position: absolute;
  bottom: 20px;
  left:50%;
  transform: translateX(-50%);
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
}
.bottomNav img{
  width:50px;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.5s ease-out;
}

.slide-right-enter-from{
  position: absolute;
  transform: translateX(-150%);
}

.slide-left-enter-from  {
  position: absolute;
  transform: translateX(130%);
}

/* .slide-right-leave-to{
  position: absolute;
  transform: translateX(150%);
} */
.slide-left-leave-to {
  position: absolute;
  transform: translateX(-130%);
}


.slide-right-leave-from, .slide-right-enter-to,
.slide-left-leave-from, .slide-left-enter-to {
  position: absolute;
  transform: translateX(0);
}

/* .fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateX(-100%);
} */
</style>
