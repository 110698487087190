import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'


import './assets/sass/style.css'

const app = createApp(App);

app.use(store);
app.use(router);

const colorPalets = [
    {
        id: 1,
        colors: [
            "#f4c790",
            "#e8a06f",
            "#db7e39",
            "#db6941",
            "#b15d34"
        ]
    },
    {
        id: 2,
        colors: [
            "#d9b4ce",
            "#e085ae",
            "#d85982",
            "#da437e",
            "#9b233e"
        ]
    }
]
const colorPaletsSecondCold = [
    {
        id: 1,
        colors: [
            "#6b3a20",
            "#9d7b67",
            "#e79f6f",
            "#b08cba",
            "#275e99",
            "#86c1d2",
            "#84c0b7",
            "#809c55",
            "#5f683d",
            "#f4df99"
        ]
    },
    {
        id: 2,
        colors: [
            "#3c3333",
            "#9d9ea1",
            "#df84ae",
            "#bca0c6",
            "#bca0c6",
            "#b5d5dd",
            "#5eb9b0",
            "#3b806a",
            "#2a635d",
            "#f7f08d"
        ]
    }
]

const colorPaletsSecondWarm = [
    {
        id: 1,
        colors: [
            // "#6d3b22",
            // "#9f7c68",
            // "#e8a070",
            // "#b08cbc",
            // "#275f9c",

            // "#87c2d2",
            // "#83c0b8",
            // "#829d56",
            // "#60693e",
            // "#f4e099"

            "#6c3b2b", 
            "#bd6b21", 
            "#f79c82", 
            "#e55322", 
            "#02639d",

            "#65c5b6", 
            "#088375", 
            "#b4d672", 
            "#709953", 
            "#f6dc5b"
        ]
    },
    {
        id: 2,
        colors: [
            // "#3e3435",
            // "#9e9fa1",
            // "#e085b0",
            // "#bba1c8",
            // "#282461",
            
            // "#b5d7e0",
            // "#5ebbb1",
            // "#39816b",
            // "#29645e",
            // "#f5f18d"

            "#3c3333", 
            "#9d9ea1", 
            "#df84ae", 
            "#9f3877", 
            "#201e63",
            
            "#b5d5dd", 
            "#5eb9b0", 
            "#3b806a", 
            "#2a635d", 
            "#f7f08d"
        ]
    }
]
const colorPaletsDimCold = [
    {
        id: 1,
        colors: [
            "#3c3132",
            "#68615e",
            "#cecece",
            "#d7b3cd",
            "#c986b4",

            "#1e365b",
            "#9cbfd9",
            "#97b5b9",
            "#2a635d",
            "#eeeda5"
        ]
    },
    {
        id: 2,
        colors: [
            "#1a1e21",
            "#3b3233",
            "#9d9ea0",
            "#de83ad",
            "#9f3877",

            "#13265c",
            "#60b2df",
            "#5fb8ae",
            "#295b66",
            "#f7ef8c"
        ]
    }
]
const colorPaletsDimWarm = [
    {
        id: 1,
        colors: [
            "#6b402d",
            "#f3c690",
            "#c05725",
            "#ae2379",
            "#14345e",

            "#0f695b",
            "#0b5b64",
            "#9ec182",
            "#6b744e",
            "#d5a423"
        ]
    },
    {
        id: 2,
        colors: [
            "#6c3a2a",
            "#f69c82",
            "#e85221",
            "#7b3c97",
            "#0c639d",

            "#62c5b6",
            "#054d6f",
            "#b4d670",
            "#6e9951",
            "#f5de5b"
        ]
    }
]
app.config.globalProperties.$doneSteps = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false,
    15: false,
    16: false,
    17: false,
    18: false,
    19: false,
    20: false,
    21: false
}
app.config.globalProperties.$colorPalets = colorPalets;
app.config.globalProperties.$colorPaletsSecondCold = colorPaletsSecondCold;
app.config.globalProperties.$colorPaletsSecondWarm = colorPaletsSecondWarm;

app.config.globalProperties.$colorPaletsDimCold = colorPaletsDimCold;
app.config.globalProperties.$colorPaletsDimWarm = colorPaletsDimWarm;

app.config.globalProperties.$userChoices = {
        palet: 0, //1 = cold - warm = 2
        dimpalet: 0,
        color: 0,
        contrast: {
            skin: 0,
            hair: 0,
            eyes: 0
        },
        shape: {
            glasses: [],
            head: 0, //1 = round - 2 = square
            brow: 0, //1 = round/thin - 2 = straight/bold - 3 = round/bold - 4 = straight/thin
            chin: 0  //1 = straight/Scurve - 2 = straigth - 3 = round - 4 = round/curve
        },
        cloth: {
            text: {
                text1: "",
                text2: "",
                text3: ""
            },
            selectedOptions: []
        },
        userInfo: {
            fname: "",
            lname: "",
            birth: "",
            email: ""
        }
}

app.config.globalProperties.$colorSteps = [
    {
        id: 1,
        title: "Met welke kleuren zie je er beter uit?",
        colorpaler: colorPalets
    }
]

const requireComponent = require.context(
    './components',
    true,
    /\.vue$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    )
    app.component(
        componentName,
        componentConfig.default || componentConfig
    )
})

app.mount('#app')