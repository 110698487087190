<template>
  <div class="home">
    <img src=".././assets/img/bg_arrow_blue.png" alt="" class="bgArrow">
    <div class="mainText">
      Ontdek welke <img src=".././assets/img/red_glasses.png" alt=""> bij jou past
    </div>
    <div class="stepText">De perfecte bril <br/> in 4 stappen</div>
    <div class="startButton" @click="start">
      <img src=".././assets/img/arrow_start_red.png" alt="">
      <div class="startButtonText">start</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  methods:{
    start(){
      this.$router.push('/steps');
    }
  },
  created(){
        this.$emit('changeBackground', 'white')
  }
}
</script>

<style scoped>
.bgArrow{
  position: absolute;
  width: 90vw;
  bottom: var(--button-bottom);
  left: var(--button-left);
}
.mainText{
  color: var(--main-color);
  font-size: 120px;
  height: 70vh;
  width: 55vw;
  font-weight: 700;
  top: 140px;
  left: 10px;
  position: absolute;
}

.mainText img{
  height: 70px;
}
.stepText{
  color: var(--main-color);
  font-weight: 600;
  position: absolute;
  bottom: var(--button-bottom);
  left: var(--button-left);
  width: 90vw;
  height: 860px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  text-align: right;
}
.startButton{
  position: absolute;
  bottom: var(--button-bottom);
  left: var(--button-left);
  width: 145vw;
  height: 850px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.startButtonText{
  position: absolute;
  font-size: 35px;
  left:45%;
  color:white;
}

.startButton img{
  width: 20vw;
}

</style>