import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomePage.vue'


const routes = [
  {
    meta: {
      depth: 0
    },
    path: '/',
    name: 'home',
    component: Home
  },
  {
    meta: {
      depth: 1
    },
    path: '/steps',
    name: 'steps',
    component: () => import('../views/StepsPage.vue')
  },
  {
    meta: {
      depth: 2
    },
    path: '/color-palet',
    name: 'color palet',
    component: () => import('../views/colors/paletPage.vue')
  },
  {
    meta: {
      depth: 3
    },
    path: '/not-think-page',
    name: 'not think page',
    component: () => import('../views/colors/NotThinkPage.vue')
  },
  {
    meta: {
      depth: 4
    },
    path: '/what-color-start-page',
    name: 'what color start page',
    component: () => import('../views/colors/WhatColorStartPage.vue')
  },
  {
    meta: {
      depth: 5
    },
    path: '/check-camera',
    name: 'check camera',
    component: () => import('../views/colors/CheckCamera.vue')
  },
  {
    meta: {
      depth: 6
    },
    path: '/color-result',
    name: 'Results Page',
    component: () => import('../views/colors/ResultsPage.vue')
  },
  {
    meta: {
      depth: 7
    },
    path: '/glass-shape',
    name: 'glass shape',
    component: () => import('../views/shape/GlassShape.vue')
  },
  {
    meta: {
      depth: 8
    },
    path: '/what-shape-start-page',
    name: 'what shape start page',
    component: () => import('../views/shape/WhatShapeStartPage.vue')
  },
  {
    meta: {
      depth: 9
    },
    path: '/all-shape',
    name: 'all shape',
    component: () => import('../views/shape/AllShape.vue')
  },
  {
    meta: {
      depth: 10
    },
    path: '/face-shape',
    name: 'face shape',
    component: () => import('../views/shape/FaceShape.vue')
  },
  {
    meta: {
      depth: 11
    },
    path: '/brow-shape',
    name: 'brow shape',
    component: () => import('../views/shape/BrowShape.vue')
  },
  {
    meta: {
      depth: 12
    },
    path: '/chin-shape',
    name: 'chin shape',
    component: () => import('../views/shape/ChinShape.vue')
  },
  {
    meta: {
      depth: 13
    },
    path: '/results-one',
    name: 'results one',
    component: () => import('../views/results/ResultsOne.vue')
  },
  {
    meta: {
      depth: 14
    },
    path: '/who-want',
    name: 'who want',
    component: () => import('../views/results/WhoWant.vue')
  },
  {
    meta: {
      depth: 15
    },
    path: '/what-gray-start-page',
    name: 'what gray start page',
    component: () => import('../views/blackwhite/WhatGrayStartPage.vue')
  },
  {
    meta: {
      depth: 16
    },
    path: '/gray-scale',
    name: 'gray scale',
    component: () => import('../views/blackwhite/GrayScale.vue')
  },
  {
    meta: {
      depth: 17
    },
    path: '/cloth-style',
    name: 'cloth style',
    component: () => import('../views/ownstyle/clothStyle.vue')
  },
  {
    meta: {
      depth: 18
    },
    path: '/tips',
    name: 'tips',
    component: () => import('../views/results/Tips.vue')
  },
  {
    meta: {
      depth: 19
    },
    path: '/before-result-page',
    name: 'befor result page',
    component: () => import('../views/results/BeforeResultPage.vue')
  },
  {
    meta: {
      depth: 20
    },
    path: '/your-info',
    name: 'your info',
    component: () => import('../views/results/YourInfo.vue')
  },
  {
    meta: {
      depth: 21
    },
    path: '/results',
    name: 'results',
    component: () => import('../views/results/results.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
