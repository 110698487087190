<template>
    <div style="display: flex;gap: 20px" @click="active = !active">
        <div style="width: 25px;height:25px; border-radius: 50%" :style="active ? 'background: #89d2d6;border: 1px solid #89d2d6;' : 'border: 1px solid #175261;'"></div>
        <img :src="active ? getOtherImgUrl(type) :getImgUrl(type)">
    </div>
</template>

<script>
export default {
    name: "image shape",
    props: ['type'],
    data(){
        return {
            active: false
        }
    },
    methods:{
        getImgUrl(imagePath) {
        return require('@/assets/img/glasses_' + imagePath + ".png");
        },
        getOtherImgUrl(imagePath){
        return require('@/assets/img/glasses_' + imagePath + "_active.png");

        },
    }
}
</script>

<style scoped>
img{
    width: 70%;
}
</style>