<template>
    <img class="pointer" :src="require('../assets/img/pointer_tilt.png')" >
</template>

<script>
export default {
    
}
</script>

<style scoped>

.pointer{
  position: absolute;
  bottom: -5%;
  width: 30px;
  left:50%;
}
</style>