<template>
    <div @click="active = !active">
        <img :src="active ? getOtherImgUrl('icon_'+button) : getImgUrl('icon_'+button) " :class="active? 'activeimg':'nonimg'" alt="">
        {{text}}
    </div>
</template>

<script>
export default {
    name: "cloth button",
    props: ['button', 'text'],
    data(){
        return {
            active: false
        }
    },
    methods:{
        getImgUrl(imagePath) {
        return require('@/assets/img/' + imagePath + ".png");
        },
        getOtherImgUrl(imagePath){
        return require('@/assets/img/' + imagePath + "_active.png");

        },
    }
}
</script>

<style scoped>
.nonimg{
    width: 30%;
    padding: 5px;
    border: 2px solid #175261;
    border-radius: 50%;
}
.activeimg{
    width: 30%;
    padding: 5px;
    border: 2px solid #89d2d6;
    border-radius: 50%;
    background: #89d2d6;
}
</style>