<template>
    <div class="largetitle" :style="color ? 'color:'+color: ''">
        <slot>
        </slot>
    </div>
</template>

<style scoped>
.largetitle{
    font-size:120px;
    font-weight: 700;
    width: 80%;
}
</style>

<script>
export default {
    name: 'LargeTitle',
    props: ['color']
}
</script>