<template>
    <div class="subtitle">
        <slot>
        </slot>
    </div>
</template>

<style scoped>
.subtitle{
    font-size:30px;
    font-weight: 700;
}
</style>

<script>
export default {
    name: 'SubTitle',
}
</script>